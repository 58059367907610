:root {
  --bg-card: #FFFFFF;
  --bg-foter: #F0F1F9;
  --bg-main: #000000;
  --bg-modal: linear-gradient(180deg, #270058 0%, #1F0046 100%);
  --bg-navbar: #270058;
  --bg-popup: linear-gradient(180deg, #8F00FF 0%, #5000B7 100%);
  --bg-text-animation: #000000;
  --bt-confirm: linear-gradient(180deg, #FFC700 0%, #FF8A00 100%);
  --bt-danger: #FF0000;
  --bt-defult: #FFFFFF;
  --bt-info: #68008C;
  --bt-login: #3C0077;
  --bt-register: linear-gradient(180deg, #00E0FF 0%, #0066FF 100%);
  --bt-secondary: #D1C4E9;
  --bt-sucess: #00D508;
  --bt-undefult: #000000;
  --bt-warning: #FFD600;
}

/* html,
body {
  overflow: hidden;
} */

body {
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
  color: var(--bg-main);
  /* background: #F0F1F9; */
  /* background: white; */
}

::placeholder {
  color: var(--bt-undefult);
  opacity: 0.8;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.kanit,
button {
  font-family: 'Sarabun', sans-serif;
}

.michroma {
  font-family: "Michroma", sans-serif;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

.chakra {
  font-family: "Chakra Petch", sans-serif;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.MuiTypography-body1 {
  font-family: 'Sarabun', sans-serif !important;
}

/* .GameApp ::-webkit-scrollbar {
  height: 18px;
  max-width: 4px;
  background: transparent;
}

.GameApp ::-webkit-scrollbar-thumb:horizontal {
  background: rgb(255, 193, 7);
  border-radius: 10px;
  cursor: pointer !important;
  filter: drop-shadow(0px 0px 8px rgb(0 0 0 / 80%));
  border: solid 2px darkgrey;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.GameApp ::-webkit-scrollbar-track {
  background-color: darkgrey;
} */

input.readOnly:read-only {
  background: #dddddd;
  color: black;
  cursor: not-allowed;
}

.textFalse {
  opacity: 0.6;
}

.bgImage {
  background: url("./images/bg/bg.webp") no-repeat center
    center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: initial;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1000;
}

/* .textColorComment {
  color: rgb(255, 193, 7);
} */
