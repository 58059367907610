:root {
  --bg-card: #FFFFFF;
  --bg-foter: #F0F1F9;
  --bg-main: #000000;
  --bg-modal: linear-gradient(180deg, #270058 0%, #1F0046 100%);
  --bg-navbar: #270058;
  --bg-popup: linear-gradient(180deg, #8F00FF 0%, #5000B7 100%);
  --bg-text-animation: #000000;
  --bt-confirm: linear-gradient(180deg, #FFC700 0%, #FF8A00 100%);
  --bt-danger: #FF0000;
  --bt-defult: #FFFFFF;
  --bt-info: #68008C;
  --bt-login: #3C0077;
  --bt-register: linear-gradient(180deg, #00E0FF 0%, #0066FF 100%);
  --bt-secondary: #D1C4E9;
  --bt-sucess: #00D508;
  --bt-undefult: #000000;
  --bt-warning: #FFD600;
}

.buttonClose {
  position: relative;
  color: var(--bt-defult);
  cursor: pointer;
  /* filter: drop-shadow(0px 0px 2px var(--bt-defult)); */
}

.buttonClose:hover {
  /* filter: drop-shadow(0px 0px 4px var(--bt-defult)); */
  top: 2px;
}

.buttonCloseImg {
  color: var(--bt-defult);
  cursor: pointer;
  /* filter: drop-shadow(0px 0px 2px var(--bt-defult)); */
}

/* .buttonCloseImg:hover {
  filter: drop-shadow(0px 0px 4px var(--bt-defult));
} */

.iconSize {
  width: 28px;
  height: 28px;
}

.iconSizeMarginTop {
  margin-top: 6px;
}

.iconMargin {
  margin-top: 8px;
}

.btnLogin {
  position: relative;
  font-size: 18px;
  width: 90%;
  padding: 1px 0px;
  /* background: var(--bt-confirm); */
  padding: 8px;
  color: var(--bt-defult);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%);
  display: inline-block;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
}

.btnLogin:hover {
  top: 2px;
} 

/* .btnDemo {
  position: relative;
  font-size: 14px;
  width: 90%;
  padding: 1px 0px;
  background: #b90101;
  padding: 8px;
  color: white;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%);
  display: inline-block;
  border-radius: 8px;
}
.btnLogin:hover,
.btnDemo:hover {
  top: 2px;
} */

/* .btnNumber {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 6px 0px #12012a;
  border-radius: 20px;
  color: #38058e;
  padding: 8px 0px;
  font-size: 18px;
  margin-bottom: 6px;
  cursor: pointer;
}

.btnLogin:hover,
.btnNumber:hover {
  top: 2px;
}

.btnNumberOrange {
  color: #ff0000 !important;
  font-weight: 600;
} */

/* .buttonTransaction {
  border: none;
  background: none;
  cursor: pointer;
  padding: 10px 12px;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 16px;
  color: #fff;
  text-align: center;
  width: 80%;
}

.buttonTransactionCancel {
  background: #dc3545;
  box-shadow: -6px 0 #ab3c3c;
}

.buttonTransactionConfirm {
  background: #00b900;
  box-shadow: 6px 0 green;
}

.buttonTransactionCancel:hover,
.buttonTransactionConfirm:hover {
  top: 2px;
} */

/* .btnPromotion {
  padding: 8px;
  text-align: center;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 20px;
}

.btnPromotionNotActive,
.btnPromotionNotActiveGreen {
  background: transparent;
}

.btnPromotionActive {
  background: linear-gradient(180deg, #ff0000 0%, #bb0000 100%);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
}

.btnPromotionActiveGreen {
  background: linear-gradient(180deg, #06c755 0%, #00712e 100%);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
}

.btnPromotionActiveGreen:hover,
.btnPromotionNotActiveGreen:hover {
  background: linear-gradient(180deg, #06c755 0%, #00712e 100%);
}

.btnPromotionNotActive:hover,
.btnPromotionActive:hover {
  background: linear-gradient(180deg, #ff0000 0%, #bb0000 100%);
} */

.btnMenuType {
  border: 1.2px solid var(--bg-main);
  border-radius: 8px;
  font-size: 20px;
  padding: 8px 40px;
  cursor: pointer;
  position: relative;
  color: var(--bg-main);
  margin: 0px 10px;
  font-weight: 600;
  width: 100px;
}

.btnMenuType:hover {
  background: var(--bg-popup);
  color: var(--bt-defult);
  border: 1.2px solid var(--bg-popup);
}

.btnMenuTypeActive {
  background: var(--bg-popup);
  color: var(--bt-defult);
  border: 1.2px solid var(--bg-popup);
}

.btnMenuTypeMargin {
  margin: 0px 10px;
}

.btnRedirect,
.btnConfirm {
  background: var(--bt-confirm);
  color: var(--bt-undefult);
  font-size: 18px;
  height: 100%;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
}

.btnRedirectComponent {
  padding: 10px;
  max-width: 240px;
  width: 100%;
  border: none;
}

.btnCancel {
  padding: 10px;
  max-width: 240px;
  width: 100%;
  border: none;
  background: var(--bt-danger);
  color: white;
}

.btnSuccess {
  padding: 10px;
  max-width: 240px;
  width: 100%;
  border: none;
  background: var(--bt-sucess);
  color: white;
}

.btnSendAgain {
  padding: 10px;
  width: 160px !important;
  font-size: 16px;
  font-weight: 300;
  border: none;
  background: var(--bt-sucess);
  color: black;
}

.btnCopy {
  padding: 10px;
  width: 160px !important;
  font-size: 16px;
  font-weight: 300;
  border: none;
  background: var(--bt-register);
  color: white;
}

.btnDemo {
  background: var(--bt-register);
  color: var(--bg-main);
}

.btnConfirm {
  padding: 14px;
  width: 100%;
  text-align: center;
  border: none;
  max-width: 280px;
}

.btnConditionSize {
  font-size: 14px;
  padding: 8px;
  border-radius: 6px;
}

.btn {
  padding: 14px;
  width: 100%;
  text-align: center;
  border: none;
  max-width: 280px;
}

.btnRedirect:hover,
.btnConfirm:hover,
.btnConditionSize:hover {
  top: 2px;
}

.btnContactSize {
  max-width: 280px;
  width: 100%;
  font-size: 18px;
  border-radius: 10px;
  font-weight: 500;
}

.btnLoginSize {
  max-width: 320px;
  width: 100%;
  font-size: 20px;
  border-radius: 40px;
  font-weight: 500;
  padding: 10px;
}

/* .btnPurple {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #360092 0%, #a60e97 100%);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 23.5px;
  width: 100%;
} */

.btnOrange {
  background: var(--bt-danger);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 23.5px;
  width: 100%;
}

.btnOutline {
  background: transparent;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 23.5px;
  width: 100%;
}

.btnLiveChat {
  background: var(--bt-confirm);
  color: var(--bt-undefult);;
  font-weight: 500;
}

.btnLine {
  background: var(--bt-sucess);
  color: var(--bt-undefult);
  font-weight: 500;
}

.btnReqBank {
  position: relative;
  background: var(--bt-confirm);
  border-radius: 10px;
  color: var(--bt-undefult);
  padding: 12px 6px;
  text-align: center;
  cursor: pointer;
}

.btnReqBank:hover {
  top: 2px;
}

.btnReqBankBlue {
  background: var(--bt-confirm);
}

.btnActionPosition {
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-54%);
}

.btnAction {
  color: var(--bt-defult);
  padding: 6px 8px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px black;
  width: 110px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

.btnSelectPromotion {
  background: var(--bt-register);
}

.btnCancelPromotion {
  background: var(--bt-danger);
}

@media only screen and (max-width: 600px) {
  .buttonShapBigSize {
    font-size: 14px;
    width: 112px;
  }
  .buttonShap {
    padding: 0px 20px 0px 24px;
  }
  .buttonMobileSize {
    width: 70px;
    font-size: 12px;
    padding: 2px 6px 2px 8px;
  }
  .iconMargin {
    margin-top: 6px;
  }
  .bgGreen,
  .bgRed,
  .bgOrange {
    padding: 4px 20px 4px 24px;
  }
  .numberButtonShape {
    font-size: 14px;
  }
  .btnPromotion,
  .btnPromotionActive {
    font-size: 14px;
  }
  .btnConfirm {
    font-size: 16px;
    padding: 12px;
  }
  .btnNumber {
    font-size: 15px;
  }
  .btnMenuType {
    font-size: 14px;
    padding: 4px 30px;
    width: 60px;
  }
  .btnContactSize {
    max-width: 280px;
    font-size: 16px;
  }
  .btnRedirect {
    font-size: 16px;
  }
  .btnMenuTypeMargin {
    margin: 0px 4px;
  }
  .btnConditionSize {
    font-size: 12px;
    padding: 6px 12px;
    border-radius: 6px;
  }
}
