:root {
  --bg-card: #FFFFFF;
  --bg-foter: #F0F1F9;
  --bg-main: #000000;
  --bg-modal: linear-gradient(180deg, #270058 0%, #1F0046 100%);
  --bg-navbar: #270058;
  --bg-popup: linear-gradient(180deg, #8F00FF 0%, #5000B7 100%);
  --bg-text-animation: #000000;
  --bt-confirm: linear-gradient(180deg, #FFC700 0%, #FF8A00 100%);
  --bt-danger: #FF0000;
  --bt-defult: #FFFFFF;
  --bt-info: #68008C;
  --bt-login: #3C0077;
  --bt-register: linear-gradient(180deg, #00E0FF 0%, #0066FF 100%);
  --bt-secondary: #D1C4E9;
  --bt-sucess: #00D508;
  --bt-undefult: #000000;
  --bt-warning: #FFD600;
}

.formControl {
  display: "flex";
  flex-wrap: "wrap";
}
.formPadding {
  padding: 0px 20px 0px 20px;
}
.boxTitle {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: var(--bt-defult);
}
.inputStyles {
  background: var(--bt-defult);
  border-radius: 10px;
  padding: 6px 0px;
  font-size: 18px;
  line-height: 21px;
  color: var(--bt-undefult);
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
}
.selectStyles {
  background: var(--bt-defult);
  border-radius: 10px;
  padding: 8px 4px;
  font-size: 18px;
  line-height: 21px;
  color: var(--bt-undefult);
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
}
.inputTextLabel {
  color: var(--bt-defult);
  padding-bottom: 12px;
  padding-left: 8px;
  font-size: 18px;
}
.inputPaddingTop {
  margin-bottom: 20px;
}
.inputPaddingRegisterTop {
  margin-bottom: 10px;
}
.errorText {
  color: #f34444;
  font-size: 14px;
}
.error {
  color: #f34444;
  font-size: 14px;
  border: solid 2px #f34444;
}
.rememberPassword {
  color: #ffd048;
  cursor: pointer;
  padding-right: 10px;
}
.btnPaddingTop {
  padding-top: "16px";
}
.borderSocial {
  border-top: solid 1px var(--bt-defult);
  position: relative;
  margin: 50px 0px 50px 0px;
  width: 100%;
}
.orPositionCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.orText {
  border: 1px solid var(--bt-defult);
  border-radius: 60px;
  font-size: 16px;
  color: var(--bt-undefult);
  padding: 12px 10px;
  background:  var(--bt-defult);
}
.inputTM {
  border: 1px solid  ;
  box-sizing: border-box;
  border-radius: 10px;
  background: transparent;
  height: 50px;
  padding: 0 10px 0 10px;
  color: var(--bt-defult);
}

.inputSelectTM {
  border: 1px solid var(--bt-defult);
  box-sizing: border-box;
  border-radius: 10px;
  height: 50px;
  color: var(--bt-undefult);
  padding: 0 10px 0 10px;
  background: var(--bt-defult);
}

.inputCheckActive {
  width: 24px;
  height: 24px;
  background: #0013be;
  border: 1px solid #898989;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 3px 0 0 3px;
}

.inputCheck {
  width: 32px;
  height: 32px;
  background: #c4c4c4;
  border: 1px solid #898989;
  box-sizing: border-box;
  border-radius: 20px;
}
.inputCheckText {
  font-size: 16px;
  color: var(--bt-defult);
}

.iconSize {
  width: 34px !important;
  height: 34px !important;
  color: var(--bt-undefult);
  margin-top: 4px;
}

.iconSizeSub {
  width: 38px;
  height: 38px;
}

@media only screen and (max-width: 600px) {
  .iconSizeSub {
    width: 28px;
    height: 28px;
  }
  .inputStyles {
    padding: 2px 0px;
  }
  .inputTextLabel {
    font-size: 14px;
  }
  .iconSize {
    width: 28px !important;
    height: 28px !important;
  }
  .borderSocial {
    margin: 40px 0px 40px 0px;
  }
  .selectStyles {
    padding: 5px 4px;
  }
}
